.App {
    text-align: center;
    background-color: white;
}

@media (prefers-color-scheme: dark) {
    .App {
        background-color: #393E46;
    }
}

.App-link {
    color: #61dafb;
}